export const seo = {
  url: 'przewoznik',
  title: {
    pl: 'Strefa Przewoźnika | Sieć Partnerów',
    en: `Carrier's Zone | Partner network`,
  },
  desc: {
    pl: 'Dołącz do sieci Partnerów Omida VLS - jednej z największej spedycji w Polsce. Realizujemy współpracę dla przewoźników w obszarze transportu krajowego oraz międzynarodowego.',
    en: 'Join the network of Omida VLS Partners - one of the largest forwarders in Poland. We offer cooperation for carriers in the field of domestic and international transport.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Strefa Przewoźnika',
    en: `Carrier's Zone`,
  },
  desc: {
    pl: `
    <span>
      Dział Floty Omida VLS </br>
      tel: +48  509 727 756
      </br></br>
      <strong>Dane do faktury:</strong></br>
      Omida VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK<br/>
      <br/>
      KRS: 0000459017<br/>
      NIP: 585 146 62 50 <br/>
    </span>
    `,
    en: `
    <span>
      <strong>Billing:</strong></br>
      </br>
      Omida VLS Sp. z o.o.</br>
      AL. GRUNWALDZKA 472C</br>
      80-309 GDAŃSK<br/>
      <br/>
      KRS: 0000459017<br/>
      NIP: 585 146 62 50 <br/>
    </span>
    `,
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Learn more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  title: {
    pl: `Sprawdź kogo szukamy <br/>i&nbsp;stań się częścią floty kontraktowej Omida VLS`,
    en: 'Carrier Zone - Join the network of Omida VLS Partners',
  },
  texts: [
    {
      pl: '<span>Dołącz do nas - jednej z największej spedycji w Polsce. Realizujemy współpracę z przewoźnikami w obszarze transportu krajowego oraz międzynarodowego. <br/><br/><strong>Poniżej znajdziesz cztery modele współpracy, które przygotowaliśmy dla naszych partnerów.</strong> <br/><br/>Jeśli jesteś zainteresowany lub masz własną propozycję – wypełnij formularz, bądź <a href="tel:+48509727756">skontaktuj się z nami</a>, podając dane Twojej firmy oraz określając potrzeby biznesowe, a nasz specjalista udzieli informacji o wszystkich, możliwych formach współpracy. </span>',
      en: '<span>Join the network of Omida VLS Partners - one of the largest forwarding companies in Poland. We offer <strong> cooperation for carriers </strong> in the field of domestic and international transport. Fill out the form below providing your company details and identifying your business needs. Specialists will inform you by e-mail or telephone while preparing a cooperation proposal. </span>',
    },
  ],
  boxes: [
    {
      title: {
        pl: `Transporty krajowe`,
      },
      desc: {
        pl: `<ul>
          <li>Zjazdy na weekend, na bazę.</li>
          <li>Współpraca dla zestawów: standard, mega, chłodnia.</li>
          <li>Stałe trasy i atrakcyjne warunki.</li>
        </ul><p>Szukamy przewoźników chcących realizować transport na terenie Polski, którzy cenią stabilność i regularne zlecenia. Zapewniamy współpracę na kontraktach dla naszych kluczowych klientów.</p><p>Masz odpowiednią flotę? Wypełnij formularz lub zadzwoń i dołącz do naszej sieci!</p>`,
      },
    },
    {
      title: {
        pl: `Kółka w eksporcie - trasy Benelux`,
      },
      desc: {
        pl: `<ul>
          <li>Trasy w kółkach: Wielkopolska – Benelux.</li>
          <li>Miesięczne przebiegi ok. 11 000 km.</li>
          <li>Stawki za każdy kilometr w kółku.</li>
          <li>Ustalony harmonogram załadunków.</li>
        </ul><p>Szukamy przewoźników z chłodniami do obsługi kontraktu dla klienta z branży spożywczej. Stabilne warunki i pewność ładunków – wszystko w ramach bezpośredniego kontraktu!</p><p>Masz chłodnię i szukasz długoterminowej współpracy? Wypełnij formularz lub zadzwoń i dołącz do naszej sieci!</p>`,
      },
    },
    {
      title: {
        pl: `Transporty międzynarodowe - tydzień`,
      },
      desc: {
        pl: `<ul>
          <li>System pracy tygodniowy.</li>
          <li>Regularne przebiegi i przewidywalne trasy - 10 tys. km.</li>
          <li>Współpraca dla zestawów: standard, mega.</li>
          <li>Stałe ładunki i atrakcyjne warunki.</li>
        </ul><p>Szukamy przewoźników do stałej współpracy na trasach międzynarodowych Polska - Czechy, Słowacja, Niemcy. Jeśli Twoja flota pokonuje 10 tysięcy kilometrów miesięcznie i szukasz stabilnych zleceń, mamy propozycję dla Ciebie!</p><p>Zainteresowany? Wypełnij formularz lub zadzwoń i dołącz do naszej sieci!</p>`,
      },
    },
    {
      title: {
        pl: `Transporty międzynarodowe +2 tygodnie`,
      },
      desc: {
        pl: `<ul>
          <li>System pracy: 2 tygodnie lub dłużej.</li>
          <li>Przebiegi minimalne: 11 000 km. miesięcznie.</li>
          <li>Współpraca dla zestawów: standard, mega, jumbo, chłodnia.</li>
          <li>Bezpośrednie zlecenia projektowe od klientów.</li>
        </ul><p>Poszukujemy przewoźników gotowych na dłuższe wyjazdy po Unii Europejskiej. Zapewniamy zlecenia projektowe bezpośrednio od klientów, gwarantując stałe przebiegi i atrakcyjne stawki za każdy kilometr.</p><p>Szukasz stabilnej współpracy na trasach UE? Wypełnij formularz lub zadzwoń i dołącz do naszej sieci!</p>`,
      },
    },
  ],
  features: [
    {
      icon: 'przewoznik1',
      subtitle: {
        pl: 'Certyfikat',
        en: 'Certificate',
      },
      title: {
        pl: 'CWB',
        en: 'CWB',
      },
      desc: {
        pl: 'Certyfikat Wiarygodności Biznesowej - Wiarygodny Płatnik',
        en: 'Certificate of Business Credibility - Credible Payer',
      },
    },
    {
      icon: 'przewoznik2',
      subtitle: {
        pl: 'Krótkie terminy',
        en: 'Fast',
      },
      title: {
        pl: 'Płatności',
        en: 'Payments',
      },
      desc: {
        pl: 'Krótkie terminy płatności na zasadzie skonta',
        en: 'Short payment terms on the basis of a discount',
      },
    },
    {
      icon: 'przewoznik6',
      subtitle: {
        pl: 'Systematyczne',
        en: 'Systematic',
      },
      title: {
        pl: 'Zlecenia',
        en: 'Orders',
      },
      desc: {
        pl: 'Gwarancja ciągłości zleceń i stabilnej współpracy',
        en: 'Guarantee of continuity of orders and stable cooperation',
      },
    },
    {
      icon: 'przewoznik3',
      subtitle: {
        pl: 'Kontakt ze spedytorem i',
        en: 'Forwarder',
      },
      title: {
        pl: 'Wsparcie',
        en: 'Support',
      },
      desc: {
        pl: 'Indywidualna opieka spedytora i działu floty',
        en: 'Individual care of the forwarder and the fleet department',
      },
    },
    {
      icon: 'przewoznik7',
      subtitle: {
        pl: 'Partnerskie',
        en: 'Partnership',
      },
      title: {
        pl: 'Relacje',
        en: 'Relations',
      },
      desc: {
        pl: 'Otwartość i równość współpracy',
        en: 'Openness and equality of cooperation',
      },
    },
    {
      icon: 'przewoznik4',
      subtitle: {
        pl: 'Całoroczna',
        en: 'Year-round',
      },
      title: {
        pl: 'Współpraca',
        en: 'Cooperation',
      },
      desc: {
        pl: 'Stała, całoroczna współpraca w kółkach',
        en: 'Permanent, year-round cooperation in circle routes',
      },
    },
    {
      icon: 'przewoznik8',
      subtitle: {
        pl: 'Duże kilometrowe',
        en: 'High',
      },
      title: {
        pl: 'Przebiegi',
        en: 'Mileage',
      },
      desc: {
        pl: 'Korzystne zlecenia transportowe',
        en: 'Profitable transport orders',
      },
    },
    {
      icon: 'przewoznik5',
      subtitle: {
        pl: 'Interesujące',
        en: 'Interesting',
      },
      title: {
        pl: 'Bonusy',
        en: 'Bonuses',
      },
      desc: {
        pl: 'Karty paliwowe, ubezpieczenie floty i wiele innych',
        en: 'Fuel cards, fleet insurance and much more',
      },
    },
  ],
}

// export const video = {
//   title: {
//     pl: 'Dołącz do projektu Fleet Carrier!',
//     en: 'Join the Fleet Carrier project!',
//   },
//   desc: {
//     pl: 'Poszukujemy przewoźników do stałej współpracy. Dlaczego warto współpracować z nami? <br/>O tym, co zyskasz, opowiedział Artur - pomysłodawca projektu Fleet Carrier.',
//     en: 'We are looking for carriers for permanent cooperation. Why is it worth cooperating with us? Arthur - the originator of the Fleet Carrier project told about what you will gain.',
//   },
//   url: 'https://www.youtube.com/watch?v=xE1Y_hzLW58',
//   more: [],
// }